var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CContainer",
    { staticClass: "d-flex align-items-center min-vh-100" },
    [
      _c(
        "CRow",
        { staticClass: "w-100 justify-content-center" },
        [
          _c("CCol", { attrs: { md: "6" } }, [
            _c("div", { staticClass: "w-100" }, [
              _c("div", { staticClass: "clearfix" }, [
                _c("h1", { staticClass: "float-left display-3 mr-4" }, [
                  _vm._v("404"),
                ]),
                _c("h4", { staticClass: "pt-3" }, [
                  _vm._v("Oops! Böyle bir sayfa yok..."),
                ]),
                _c("p", { staticClass: "text-muted" }, [
                  _vm._v("Aradığınız sayfa bulunamadı. "),
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.go(-1)
                        },
                      },
                    },
                    [_vm._v("(geri)")]
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }